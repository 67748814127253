import React from 'react';

const Hero = ({ primaryContent, secondaryContent, coverImage, fullHeight }) => (
  <section
    className={`py-12 lg:py-48 bg-cover text-white bg-center shade ${fullHeight && `h-screen`}`}
    style={{backgroundImage: "url(" + coverImage + ")"}}
  >
    <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row mb-12">
      <div className="lg:w-2/3">
        <h1 className="text-4xl lg:text-6xl font-thin leading-none">
          {primaryContent}
        </h1>
      </div>
      <div class="mt-10 lg:mt-0 w-full lg:w-1/3">
        {secondaryContent}
      </div>
    </div>
  </section>
);

export default Hero;

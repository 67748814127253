import React from 'react';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import Hero from '../components/Hero';
import { Helmet } from 'react-helmet';
import imgOurFirm from '../images/our-firm.jpg';
import imgOurFirm1 from '../images/our-firm-1.jpg';
import imgOurFirm2 from '../images/our-firm-2.jpg';
import imgOurFirm3 from '../images/our-firm-3.jpg';
import ShareImg from "../images/share.png";

export default () => (
  <Layout>
    <Hero
      coverImage={ imgOurFirm } 
      primaryContent={
        <span>Our Firm</span>
      }
    />

    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <p className="mt-8 text-xl font-light leading-relaxed">
            Our strategic and advisory firm’s culture is a great fit with
            companies that are having difficulty realizing their operational
            and/or financial objectives. Our firm’s broad business, operational
            and financial knowledge has us well suited to provide the support
            services and advice to our target client’s – Closely held businesses
            ranging in revenue from $ 10 million to $500 million.
          </p>
        </div>
      }
      secondarySlot={
        <img src={ imgOurFirm1 } alt=""/>
      }
    />

    <SplitSection
      reverseOrder
      shaded
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <p className="mt-8 text-xl font-light leading-relaxed">
            Hollis Meddings Group understands the practical realities of doing
            business in uncertain times as well as the challenges of running a
            family based entity where management and ownership are often the
            same. Our approach is cooperative and flexible, as every situation
            and client is different. We work up front to lay out the proper
            scope of the engagement, and continue to refine the scope as the
            situation evolves.
          </p>
        </div>
      }
      secondarySlot={
        <img src={ imgOurFirm2 }  alt=""/>
      }
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <p className="mt-8 text-xl font-light leading-relaxed">
            For over thirty years, HMG has been working closely with management
            teams to improve profitability, enhance growth, and increase
            shareholder value. Our team members have the real-world experience
            to identify problems, formulate corrective strategies, and implement
            those strategies.
          </p>
        </div>
      }
      secondarySlot={
        <img src={ imgOurFirm3 } alt="" />
      }
    />
    <Helmet>
      <meta charSet="utf-8" />
      <title>Our Firm | Hollis Meddings Group</title>
      <meta name="description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta property="og:title" content="Hollis Meddings Group"/>
      <meta property="og:description" content="Hollis Meddings Group understands the practical realities of doing business in uncertain times as well as the challenges of running a family based entity where management and ownership are often the same."/>
      <meta property="og:image" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:url" content="https://www.hollismeddings.com"/>
      <meta name="twitter:card" content={ 'https://www.hollismeddings.com'+ShareImg }/>
      <meta property="og:site_name" content="Hollis Meddings Group"/>
      <meta name="twitter:image:alt" content="Hollis Meddings Group"/>
    </Helmet>
  </Layout>
);

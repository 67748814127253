import React from 'react';

const SplitSection = ({
  id,
  primarySlot,
  secondarySlot,
  optionalList,
  reverseOrder,
  shaded,
}) => (
  <section id={id} className={`py-20 ${shaded && `bg-gray-300`}`}>
    <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row mb-12">
      <div className="lg:w-2/3">{primarySlot}</div>
      <div
        className={`mt-10 lg:mt-0 w-full lg:w-1/3 ${reverseOrder &&
          `order-last lg:order-first`}`}
      >
        {secondarySlot}
      </div>
    </div>
    {optionalList && (
      <>
        <div className="container mx-auto px-12 items-center">
          {optionalList}
        </div>
      </>
    )}
  </section>
);

export default SplitSection;

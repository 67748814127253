import React from 'react';
import { Link } from "gatsby"
import LogoIconFull from '../../svg/LogoFull';

const Footer = () => (
  <footer className="bg-gray-900 text-gray-300">
    <div className="container mx-auto py-16 px-3">
    <div className="md:flex -mx-3">
      <div className="w-full md:w-2/4 px-3 mb-6">
        <div className="mb-4 logo">
          <LogoIconFull />
        </div>
        <h2 className="text-lg font-semibold mb-5"><a class="text-gray-400 hover:text-gray-500" href="tel:14014213330">(401) 421-3330</a></h2>
        {/* <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
          <a href="https://www.facebook.com" class="text-gray-600 hover:text-white cursor-pointer"> <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24"> <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path> </svg> </a>
          <a href="https://www.twitter.com" class="ml-3 text-gray-600 hover:text-white cursor-pointer"> <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24"> <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path> </svg> </a>
          <a href="https://www.instagram.com" class="ml-3 text-gray-600 hover:text-white cursor-pointer"> <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24"> <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect> <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path> </svg> </a>
          <a href="https://www.linkedin.com" class="ml-3 text-gray-600 hover:text-white cursor-pointer"> <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24"> <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path> <circle cx="4" cy="4" r="2" stroke="none"></circle> </svg> </a>
        </span> */}
      </div>
      <div className="w-full md:w-1/4 px-3 mb-6">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li> <Link to="/" className="text-gray-400 hover:text-gray-500">Our Firm</Link> </li>
          <li> <Link to="/service-offerings" className="text-gray-400 hover:text-gray-500">Service Offerings</Link> </li>
          <li> <Link to="/our-leadership" className="text-gray-400 hover:text-gray-500">Our Leadership</Link> </li>
          <li> <Link to="/contact-us" className="text-gray-400 hover:text-gray-500">Contact Us</Link> </li>
        </ul>
      </div>
      <div className="w-full md:w-1/4 px-3 mb-6">
        <h2 className="text-lg font-semibold">Location</h2>
        <ul className="mt-4 leading-loose">
          <li> 1481 Wampanoag Trail, Suite 3</li>
          <li> Providence, Rhode Island</li>
          <li> 02915 </li>
        </ul>
      </div>
    </div>
    </div>
  </footer>
);

export default Footer;
